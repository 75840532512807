<template>
<div>
    <!-- <v-row v-show="$vuetify.breakpoint.mobile"></v-row>
        <v-row style="margin-top: 0%" v-show="!$vuetify.breakpoint.mobile"></v-row> -->
    <waiting-modal :title="title" :status="status" :active="active_waiting" @statusdailog="update_status_wait"></waiting-modal>
    <v-dialog v-model="dialog" width="250">
        <v-card>
            <v-card-title class="text-purple" style="font-weight: 300; font-size: 16px">
                {{ selected_row.provider_name }}
                <!-- {{ selected_row.booking_rec_id}} -->
            </v-card-title>
            <v-card-text>
                <v-list-item three-line>
                    <!-- {{currentDate()}}
                    {{new Date(selected_row.booking_date)}} -->
                    <v-list-item-content>
                        <!-- <v-list-item-title><b>User id : </b> &nbsp;{{ selected_row.user_id }}</v-list-item-title> -->
                        <v-list-item-title><b>ชื่อ : </b> &nbsp; {{ selected_row.patient_name }}</v-list-item-title>
                        <v-list-item-title><b>สถานะไลน์ : </b> &nbsp;{{ selected_row.line_status ? 'มีไลน์' : 'ไม่มีไลน์' }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-card-text>
            <v-card-actions style="    justify-content: space-between;">
                <v-btn @click="cf_dialog = true" class="ma-2" x-small outlined fab color="red">
                    <v-icon>delete_forever</v-icon>
                </v-btn>
                &nbsp;
                <v-btn color="bg-purple text-white" @click="dialog = false">ตกลง</v-btn>

            </v-card-actions>
        </v-card>
    </v-dialog>
    <v-dialog v-model="cf_dialog" width="300">
        <v-card>
            <v-card-text style="text-align:center">
                <br>
                <v-btn class="ma-2" small outlined fab color="red">
                    <v-icon>delete_forever</v-icon>
                </v-btn>
                <br>
                <span>ยืนยันการลบผู้ป่วยจากการดูแล</span>

                <br>
            </v-card-text>
            <v-card-actions class="d-flex justify-space-around">
                <v-spacer></v-spacer>
                <v-btn small outlined style="color:purple" @click="cf_dialog = false">ยกเลิก</v-btn>
                <v-spacer></v-spacer>
                <v-btn small color="bg-purple text-white" @click="delRelation">ตกลง</v-btn>
                <v-spacer></v-spacer>
            </v-card-actions>
        </v-card>
    </v-dialog>
    <v-dialog v-model="token_dialog" width="300">
        <v-card>
            <v-card-title>New token</v-card-title>
            <v-card-text persistent style="text-align:center">

                <br>
                <v-chip label>{{new_token}}</v-chip>
            </v-card-text>
            <v-card-actions class="d-flex justify-space-around">

                <v-spacer></v-spacer>
                <v-btn color="bg-purple text-white" @click="$router.go()">ตกลง</v-btn>

            </v-card-actions>
        </v-card>
    </v-dialog>
    <v-dialog v-model="req_dialog" width="300">
        <v-card>
            <v-card-title>New token</v-card-title>
            <v-card-text persistent style="text-align:center">
                ระบบได้ทำการส่ง Token ไปยังผู้ขอผ่านช่องทางไลน์แล้ว
                <br>
                <v-chip label>{{new_token}}</v-chip>
            </v-card-text>
            <v-card-actions class="d-flex justify-space-around">

                <v-spacer></v-spacer>
                <v-btn color="bg-purple text-white" @click="$router.go()">ตกลง</v-btn>

            </v-card-actions>
        </v-card>
    </v-dialog>
    <v-container>
        <v-row class="align-center d-flex h-0 pb-0 pt-2">
            <v-col lg="10" sm="12" class="mx-auto">
                <v-row class="d-flex">
                    <v-col cols="12" style="text-align: right;padding-bottom: 0px;">
                        <v-btn elevation="2" @click="createToken" tile color="bg-orange" class="text-purple" style="box-shadow: 0px 1px 3px rgba(153, 153, 153, 0.12);border-radius: 7px;">
                            <v-icon left>
                                key
                            </v-icon>
                            Generate token
                        </v-btn>
                    </v-col>
                </v-row>
                <br />
                <span>Eisai Token list</span>
                <!-- user_provider_id -->
                <v-row class="d-flex">
                    <v-col cols="12" style="">
                        <v-card elevation="1">
                            <v-text-field v-model="search_1" append-icon="search" label="" single-line hide-details style="padding-top: 0px;margin-top: 0px;background-color: white;border-top-left-radius: 5px;border-top-right-radius: 5px;" flat></v-text-field>
                            <!-- <v-data-table :search="search" :disable-pagination="true" :headers="headers" :items="patient_list" :items-per-page="10" dense mobile-breakpoint @click:row="handleClick"> -->
                            <v-data-table hide-default-footer :search="search_1" :disable-pagination="false" :headers="headers_token" :items="token_list" :items-per-page="itemsPerPage_1" mobile-breakpoint :page.sync="page_1" @page-count="pageCount_1 = $event">

                                <template v-slot:item.is_activate="{ item }">
                                    <div>

                                        <v-chip v-if="item.is_activate == 1" class="ma-2" color="grey" text-color="white" small>Activated</v-chip>

                                        <v-chip v-else-if="item.is_activate == 0" class="ma-2" color="green" text-color="white" small>Available</v-chip>

                                        <v-chip v-else-if="item.is_activate == 3" class="ma-2" color="orange" text-color="white" small>Wating</v-chip>

                                    </div>

                                </template>

                                <!-- <template v-slot:item.action="{ item }">
                                    <div v-if="item.action == false"></div>
                                    <div v-else>
                                        <v-btn tile @click="goAzq(item)" color="bg-orange" x-small height="32" class="text-purple" style="box-shadow: 0px 1px 3px rgba(153, 153, 153, 0.12);border-radius: 7px;">
                                            <span>ทดสอบ<br>AZQ</span>
                                        </v-btn>
                                    </div>
                                </template> -->
                            </v-data-table>

                        </v-card>
                        <div class="text-center pt-2">
                            <v-pagination v-model="page_1" :length="pageCount_1"></v-pagination>
                        </div>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>

    </v-container>

    <v-container>
        <v-row class="align-center d-flex h-0 pb-0 pt-2">
            <v-col lg="10" sm="12" class="mx-auto">
                <br />
                <span>รายการคำขอ</span>
                <v-row class="d-flex">
                    <v-col cols="7" style="padding-bottom: 0px;"> <span style="font-size: 14px;">ทั้งหมด <b>{{req_list.length}}</b> คน</span></v-col>

                </v-row>
                <v-row class="d-flex">
                    <v-col cols="12" style="padding-top: 0px !important;">
                        <v-card elevation="1">
                            <v-text-field v-model="search" append-icon="search" label="" single-line hide-details style="padding-top: 0px;margin-top: 0px;background-color: white;border-top-left-radius: 5px;border-top-right-radius: 5px;" flat></v-text-field>
                            <!-- <v-data-table :search="search" :disable-pagination="true" :headers="headers" :items="patient_list" :items-per-page="10" dense mobile-breakpoint @click:row="handleClick"> -->
                            <v-data-table hide-default-footer :search="search" :disable-pagination="false" :headers="headers_request" :items="req_list" :items-per-page="itemsPerPage" mobile-breakpoint :page.sync="page" @page-count="pageCount = $event">
                                <template v-slot:item.action="{ item }">
                                    <v-btn v-if="item.status == 'new'" elevation="2" @click="createTokenThenSend(item)" color="bg-orange" x-small height="32" class="text-purple" style="box-shadow: 0px 1px 3px rgba(153, 153, 153, 0.12);border-radius: 7px;">
                                        <span>สร้าง Token และส่ง</span>
                                    </v-btn>

                                    <v-btn v-else disabled color="bg-orange" x-small height="32" class="text-purple" style="box-shadow: 0px 1px 3px rgba(153, 153, 153, 0.12);border-radius: 7px;">
                                        <span>สร้าง Token และส่ง</span>
                                    </v-btn>

                                </template>
                            </v-data-table>
                        </v-card>
                        <div class="text-center pt-2">
                            <v-pagination v-model="page" :length="pageCount"></v-pagination>
                        </div>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>

    </v-container>
</div>
</template>

<script>
import CheckmongHomeBar from "@/components/CheckmongHomeBar.vue";
import {
    APIURL
} from '@/constants'
import {
    LIFFID
} from '@/constants'
import Vue from 'vue'
import WaitingModal from "@/components/WaitingModal.vue";
export default {
    name: "calendar-patient",
    components: {
        CheckmongHomeBar,
        WaitingModal
    },
    data: () => ({
        req_dialog: false,
        token_dialog: false,
        req_list: [],
        token_list: [],
        page: 1,
        pageCount: 0,
        itemsPerPage: 10,

        page_1: 1,
        pageCount_1: 0,
        itemsPerPage_1: 10,
        new_token: '',

        cf_dialog: false,
        patient_list: [],
        active_waiting: false,
        title: '',
        status: '',
        search: '',
        search_1:'',
        attrs: "",
        on: "",
        selected_row: "",
        dialog: false,
        arrayEvents: null,
        bg_sound: new Audio(require('@/assets/img/checkmong/azq/audio/bg_sound.mp3')),
        date1: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
            .toISOString()
            .substr(0, 10),
        date2: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
            .toISOString()
            .substr(0, 10),

        headers_token: [{
                text: "No.",
                value: "id",
            },
            {
                text: "Token",
                value: "reg_token",
            },
            {
                text: "created date",
                value: "create_date",
            },
            {
                text: "Activated by",
                value: "full_name",
            },
            {
                text: "Phone",
                value: "phone_number",
            },
            {
                text: "Status",
                value: "is_activate",
            },
            {
                text: "",
                value: "action",
            },
        ],
        headers_request: [{

                text: "No.",
                value: "request_token_id",
            },
            {

                text: "Request date",
                value: "created_date",
            },
            {
                text: "ผู้ขอ",
                value: "full_name",
            },
            // {
            //     text: "Contract",
            //     value: "contract",
            // },

            {
                text: "Status",
                value: "status",
            },
            {
                text: "",
                value: "action",
            },
        ],
        // patient_list: [{
        //         id: "1",
        //         patient_name: "ชัชวาลย์ รัตนโสภา",
        //         line_status: true,
        //         action: false
        //     },
        //     {
        //         id: "2",
        //         patient_name: "ลลิตา รัตนโสภา",
        //         line_status: false,
        //         action: true
        //     },
        // ],
    }),

    beforeCreate() {
        if (!this.$store.state.is_admin_login.is_admin_login) {
            this.$router.push('/token_management_login')
            this.getPatientList()
        }
    },
    mounted() {
        this.getRequestTokenList()
    },
    watch: {

    },
    methods: {

        del_user(val) {
            this.selected_row = val
            this.cf_dialog = true
        },

        async createToken() {
            //console.log('his.selected_row.user_relation_id:',this.selected_row.user_relation_id)
            this.active_waiting = true
            this.title = '',
                this.status = 'loading'
            await Vue.axios.post(APIURL + '/create_token', {}, {
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then((response) => {
                if (response.data.detail) {
                    this.active_waiting = false
                    this.token_dialog = true
                    this.new_token = response.data.detail
                    // console.log(response.data.detail)
                    // this.active_waiting = true
                    // this.title = '<b>New Token:</b> <br>'+response.data.detail,
                    // this.status = 'info'
                    // this.$router.go()
                    // this.dialog_edit = false
                    // this.dialog = false
                    // this.getBookingList()
                    // this.$router.go()

                    // this.$store.commit('setUserInfo',response.data);
                    // this.district = response.data.district
                    // this.subdistrict_list = response.data.location_list
                    // this.location_id = response.data.location_list
                } else {
                    this.active_waiting = false

                }

            })
        },

        async createTokenThenSend(item) {
            console.log(item)
            console.log('his.selected_row.user_relation_id:', this.selected_row.user_relation_id)
            this.active_waiting = true
            this.title = '',
                this.status = 'loading'
            await Vue.axios.post(APIURL + '/create_token_then_send', {
                "lineid": item.lineid,
                "contract": item.contract,
                "is_acivate": 3,
                "request_token_id": item.request_token_id,

            }, {
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then((response) => {
                if (response.data.detail) {
                    this.active_waiting = false
                    this.req_dialog = true
                    this.new_token = response.data.detail
                    // this.token_dialog = true
                    // this.new_token = response.data.detail
                    // console.log(response.data.detail)
                    // this.active_waiting = true
                    // this.title = '<b>New Token:</b> <br>'+response.data.detail,
                    // this.status = 'info'
                    // this.$router.go()
                    // this.dialog_edit = false
                    // this.dialog = false
                    // this.getBookingList()
                    // this.$router.go()

                    // this.$store.commit('setUserInfo',response.data);
                    // this.district = response.data.district
                    // this.subdistrict_list = response.data.location_list
                    // this.location_id = response.data.location_list
                } else {
                    this.active_waiting = false

                }

            })
        },

        async delRelation() {
            //console.log('his.selected_row.user_relation_id:',this.selected_row.user_relation_id)
            this.active_waiting = true
            this.title = 'กำลังลบข้อมูล',
                this.status = 'loading'
            await Vue.axios.post(APIURL + '/del_relation', {
                "user_relation_id": this.selected_row.user_relation_id
            }, {
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then((response) => {
                if (response.data) {
                    this.active_waiting = false
                    this.dialog_edit = false
                    this.dialog = false
                    // this.getBookingList()
                    this.$router.go()

                    // this.$store.commit('setUserInfo',response.data);
                    // this.district = response.data.district
                    // this.subdistrict_list = response.data.location_list
                    // this.location_id = response.data.location_list
                } else {
                    this.active_waiting = false

                }

            })
        },

        async getRequestTokenList() {
            this.active_waiting = true
            this.title = 'กำลังอัพเดต',
                this.status = 'loading'
            await Vue.axios.post(APIURL + '/get_request_token_list', {

            }, {
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then((response) => {
                //console.log(response)
                if (response.data) {
                    this.active_waiting = false
                    // print()

                    this.req_list = response.data.req_list
                    this.token_list = response.data.token_list

                    console.log('this.req_list', this.req_list)
                    console.log('this.token_list', this.token_list)
                    // this.patient_list = response.data.patient_list;
                    //console.log('777777777777777')
                    //console.log(this.patient_list)
                } else {
                    this.active_waiting = false
                }

            })

        },

        update_status_wait(val) {
            this.active_waiting = val
        },
        handleClick(value) {
            this.selected_row = value;
            this.dialog = true;
        },

    },
};
</script>

<style>
.theme--light.v-pagination .v-pagination__item--active {
    color: rgb(0 0 0 / 87%) !important;
}
</style>
